#headline {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 0);
}

#home {
  font-family: 'Montserrat', sans-serif;
  font-size:calc(18px + 3vw);
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  letter-spacing: calc(1px + 1.5vw);
}

#tagline {
  font-size:calc(8px + 0.5vw);
  letter-spacing: 4.5px;
  padding: 15px 0;
  text-align: center;
}

#keylinks {
  text-align: center;
}

/* ----- SCROLL ICON ----- */
.arrow{
  position: absolute;
  bottom: 20%;
  left: 40%;
  width: 20px;
}

.arrow span{
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2){
  animation-delay: -0.2s;
}

.arrow span:nth-child(3){
  animation-delay: -0.4s;
}

@keyframes animate {
  0%{
      opacity: 0;
      transform: rotate(45deg) translate(-20px,-20px);
  }
  50%{
      opacity: 1;
  }
  100%{
      opacity: 0;
      transform: rotate(45deg) translate(20px,20px);
  }
}
