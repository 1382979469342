
#burger {
  display: inline-block;
  cursor: pointer;
  z-index: 1001;
  position: absolute;
  right: 25px;
  top: 25px;
}

.bar1, .bar2, .bar3 {
  width: 25px;
  height: 2px;
  background-color: #0D0D0D;
  margin: 6px 0;
  transition: 0.4s;
}

@media (min-width: 768px) {
  #burger {
    display: none;
  }
}