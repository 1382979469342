button {
  width: 175px;
  padding: 10px 25px;
  margin: 10px;
  border: none;
  border-radius: 7.5px;
  background-color: #000000;
  box-shadow: 0 3px 10px #00000075
}

a {
  text-decoration: none;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 2.5px;
}

button:hover {
  box-shadow: none;
}

a:hover {
  text-decoration: underline;
}