#navbar {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 75px;
  z-index: 100;
  background: #ffffff;
}

#logo {
  width: 20vw;
  max-width: 100px;
  margin-left: 20px;
}

#logo #site-title {
  position: absolute;
  color: #000000;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  padding: 20px 40px 20px 0px;
  text-decoration: none;
}

#logo img {
  width: 100%;
}

#menuContainer {
  position: absolute;
  right: 0;
  top: 0;
  padding: 25px 40px 25px 0px;
  width: 40%;
  max-width: 480px;
  text-align: right;
}

#menu {
  display: flex;
  justify-content: space-between;
}

.navlink {
  color: #0D0D0D;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  #menu {
    height: 100vh;
    top: 0;
    padding-top: 10vh;
    right: -1000px;
    width: 60vw;
    flex-direction: column;
    justify-content: left;
    margin-bottom: 30vh;
    align-items: center;
    background-color: #F5F5F5;
    box-shadow: 0 0 100px rgba(0,0,0,0.7);
    position: absolute;
  }
  .navlink {
    padding: 25px 25px;
    width: 60%;
    text-align: center;
    border-bottom: 1px solid grey;
  }
}