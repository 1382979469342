.page {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  scroll-margin-top: 200px;
}

.page > * {
  padding: 0 10vw;
  width: 80vw;
}

.hidden {
  visibility: hidden;
}

.coming-soon {
  position: absolute;
  top: 30vh;
  width: 20vh;
  margin: 0 50%;
  transform: translate(-50%, 0);
  display: block;
}

.page > h3 {
  visibility: hidden;
}

@media (max-width: 992px) {
  h3 {
    position: static;
    margin: 0;
    font-size: 2.5em;
  }
}