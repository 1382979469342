#contact-form {
  width: 80%;
  position: absolute;
  top: 20vh;
}

#contact-instruction {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

#contact-form textarea {
  font-family: 'Open Sans', sans-serif;
}

@media (max-width: 992px) {

  #contact-form {
    top: 15vh;
  }

}