html {
  scroll-behavior: smooth;
  scroll-padding-top: 150px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

p, a {
  font-family: 'Open Sans', sans-serif;
}