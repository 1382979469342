#skillcards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10vh 0;
}

@media (max-width: 992px) {
  #skillcards {
    flex-direction: column;
  }
}
