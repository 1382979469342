form div {
  width: 60%;
  margin: 0 auto;
}

form label {
  display: none;
  padding: 1vh;
}

form input, textarea{
  width: 95%;
  height: 5vh;
  margin-bottom: 2.5vh;
  padding: 0 2.5%;
  border: 1px solid gray;
  touch-action: none;
}

form textarea {
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  height: 20vh;
}

#form-submit-button {
  width: 30%;
  display: block;
  margin: 0 auto;
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0 3px 10px #00000075;
  cursor: pointer;
  padding: 1.5vh 0;
  height: 5vh;
}

#form-submit-button:hover {
  background-color: #383838;
}

#form-submit-button:active {
  box-shadow: none;
}

@media (max-width: 992px) {

  form div {
    width: 80%;
  }

  #form-submit-button {
    width: 50%;
  }

}

@media (max-width: 768px) {

  form div {
    width: 95%;
  }

  #form-submit-button {
    width: 80%;
  }

}
